/* =========================================================================================
  File Name: user.js
  Description: Calendar Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

import state from './state.js'
import actions from './actions.js'
import { getField, updateField } from 'vuex-map-fields'
import _ from 'lodash'
export default {
}
